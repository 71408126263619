<template>
	<div>
		<div class="left-nav">
			<div v-if="list.length===0">
				<slot></slot>
			</div>
			<div v-if="list.length>0">
		   		<div class="nav-ink"><span :style="{top: 39+idx*30+'px'}"></span></div>
		   		<div class="title">{{title}}</div>
		   		<ul>
		   			<li v-for="(item,index) in list">
		   				<a @click="navClick(item.idx,item)" :class="{active:selectId===item.child[0].id}">{{item.name}}</a>
		   				<!-- <a @click="navClick(subitem.idx,subitem)" :class="{active:idx===subitem.idx}" v-for="(subitem,index) in item.child" style="margin-left:15px;">{{subitem.name}}</a> -->
		   			</li>
		   		</ul>
	   		</div>
		</div>
		<div class="mobile-nav">
			<div class="btn" :class="{'close':mobileNavIsShow}" @click="mobileNavSwitch()">
				<span><svg viewBox="0 0 926.23699 573.74994" version="1.1" x="0px" y="0px" width="16" height="16" class="css-cf8c4v"><g transform="translate(904.92214,-879.1482)"><path d="
          m -673.67664,1221.6502 -231.2455,-231.24803 55.6165,
          -55.627 c 30.5891,-30.59485 56.1806,-55.627 56.8701,-55.627 0.6894,
          0 79.8637,78.60862 175.9427,174.68583 l 174.6892,174.6858 174.6892,
          -174.6858 c 96.079,-96.07721 175.253196,-174.68583 175.942696,
          -174.68583 0.6895,0 26.281,25.03215 56.8701,
          55.627 l 55.6165,55.627 -231.245496,231.24803 c -127.185,127.1864
          -231.5279,231.248 -231.873,231.248 -0.3451,0 -104.688,
          -104.0616 -231.873,-231.248 z
        " fill="currentColor"></path></g></svg></span>
        		<span>
        			<svg viewBox="0 0 926.23699 573.74994" version="1.1" x="0px" y="0px" width="16" height="16" class="css-cf8c4v"><g transform="translate(904.92214,-879.1482)"><path d="
          m -673.67664,1221.6502 -231.2455,-231.24803 55.6165,
          -55.627 c 30.5891,-30.59485 56.1806,-55.627 56.8701,-55.627 0.6894,
          0 79.8637,78.60862 175.9427,174.68583 l 174.6892,174.6858 174.6892,
          -174.6858 c 96.079,-96.07721 175.253196,-174.68583 175.942696,
          -174.68583 0.6895,0 26.281,25.03215 56.8701,
          55.627 l 55.6165,55.627 -231.245496,231.24803 c -127.185,127.1864
          -231.5279,231.248 -231.873,231.248 -0.3451,0 -104.688,
          -104.0616 -231.873,-231.248 z
        " fill="currentColor"></path></g></svg>
        		</span>
				<!-- <DownOutlined />
				<UpOutlined /> -->
			</div>
			<div class="wrap" :class="{'show':mobileNavIsShow}">
				<div v-if="list.length===0">
					<slot></slot>
				</div>
				<div v-if="list.length>0">
					<div class="title">{{title}}</div>
					<ul>
			   			<li v-for="(item,index) in list">
		   				<a @click="navClick(item.idx,item)" :class="{active:idx===item.idx}">{{item.name}}</a>
		   				<!-- <a @click="navClick(subitem.idx,subitem)" :class="{active:idx===subitem.idx}" v-for="(subitem,index) in item.child" style="margin-left:15px;">{{subitem.name}}</a> -->
		   				</li>
			   		</ul>
			   	</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref,onMounted,watch,computed} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import { DownOutlined, UpOutlined} from '@ant-design/icons-vue';

export default {
	name: '',
	components:{DownOutlined, UpOutlined},
	props:{
		title:{type:String,default:''},
		list:{type:Array,default:[]},
		mobileNavIsShow:{type:Boolean, default:false}
	},
	setup(props,{emit}){
		const idx = ref(null);
		const selectId = ref(null)
		const mobileNavIsShow = ref(false);
		// const router = useRouter();
		const route = useRoute();

		watch(props,()=>{
			console.log("list change");
			console.log(route.params.id);
			let paramsId = route.params.id;
			selectId.value = paramsId;
			if(paramsId===undefined){
				paramsId = props.list[0].child[0].id;
			}
			props.list.forEach((item,index)=>{
				// console.log(item.idx,paramsId);
				if(item.child[0].id==paramsId){
					idx.value = item.idx;
					selectId.value = item.child[0].id;
					emit("richText",item.child[0]);
					return false;
				}
			})
		})
		
		const navClick = (index,item)=>{
			idx.value=index;
			selectId.value = item.child[0].id;
			console.log(item);

			mobileNavIsShow.value=false;
			emit("richText",item.child[0]);
		}

		const mobileNavSwitch = ()=>{
			mobileNavIsShow.value = !mobileNavIsShow.value;
		}
 		return {idx,selectId,navClick,mobileNavIsShow,mobileNavSwitch}
	}
}
</script>

<style scoped>
.left-nav{padding: 7px 0 7px 16px; line-height: 1.143;  position: relative; font-size:0;}
.left-nav .nav-ink{position: absolute; top: 0; left: 0; height: 100%;}
.left-nav .nav-ink::before {position: relative; display: block; width: 2px; height: 100%; margin: 0 auto;background-color: #f0f0f0;content: ' ';}
.left-nav .nav-ink>span{position: absolute; left: 50%;display: block; width: 8px;height: 8px; background-color: #fff; border: 2px solid #1890ff; border-radius: 8px;transform: translateX(-50%);transition: top 0.3s ease-in-out;}
.left-nav .title{margin-bottom: 6px; font-size:14px;}
.left-nav ul li{line-height: 30px; font-size:14px;}
.left-nav ul li a{display:block; padding: 0px 0 0px 16px; color:#666; line-height: 30px; }
.left-nav ul li a.active{color: #1890ff;}
.left-nav ul li a.router-link-active{color: #1890ff;}

.mobile-nav{display:none;}

@media screen and (max-width:800px) {
.left-nav{display:none;}
.mobile-nav{display:block;}
.mobile-nav .wrap{ padding:20px 0 0 20px; position: fixed; z-index:99; top:44px; left:0; right:0; bottom:0; background: rgba(255,255,255,0);
visibility: hidden; transform: translateY(30px); opacity:0; transition-duration: 300ms;
}
.mobile-nav .wrap.show{visibility: visible; transform: translateY(0); opacity:1; background: rgba(255,255,255,1)}
.mobile-nav .title{margin-bottom: 6px; font-size:14px; color:#111; font-weight: bold;}
.mobile-nav ul li{line-height: 30px; font-size:14px;}
.mobile-nav ul li a{display:block; padding: 0px 0 0px 16px; color:#666; line-height: 30px; }
.mobile-nav ul li a.active{color: #1890ff;}
.mobile-nav ul li a.router-link-active{color: #1890ff;}

.btn{width:56px; height:56px; background: rgba(255,255,255,.9); border-radius: 60px; position: fixed; z-index:999; bottom:20px; right:20px; box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 20px;
display:flex; flex-direction: column; justify-content: center; align-items: center;
}

.btn span{font-size:16px; color:#fff;width:16px; display:block; transition-duration: 300ms;}
.btn.close span:nth-of-type(1){ transform: translateY(9px);}
.btn.close span:nth-of-type(2){transform: translateY(-9px) rotate(180deg);}
.btn span:nth-of-type(1){ transform: translateY(20px); color:#1890ff;}
.btn span:nth-of-type(2){transform: translateY(-20px) rotate(180deg); color:#1890ff;}
}

</style>