<template>
  <div class="detail">

  		<backHeader title="返回"></backHeader>
     
      	<div class="wrap">
	        <div class="leftbox">
	             <div class="nav-box">
	               <leftNav ref="navRef" :list="list" title="应用案例" @richText="richTextChange">
		               <!-- <a-anchor :affix="false" :target-offset="targetOffset" @click="handleClick">
		               		<a-anchor-link href="#product" title="核心装备">
		               			<a-anchor-link v-for="(item,index) in list" href="#"  :title="item.name" :id="item.id">
		               				<a-anchor-link v-for="(subitem,index) in item.child" :title="subitem.name" :sid="subitem.id" />
		               			</a-anchor-link> 
		                    </a-anchor-link>
		               </a-anchor> -->
	               </leftNav>
	             </div>
	         </div>

	         <div class="rightbox">

	           <div class="product">
	               <h2 id="product" style="margin-top:10px;">{{richText.name}}</h2>
	 			         <div class="rich-text" v-html="richText.content"></div>
	           </div>

	        </div>
        </div>
      <!-- <back-top></back-top> -->
      <d-footer ></d-footer>
   </div>
</template>

<script>
import { ref,onMounted,watch,computed} from 'vue'
// import {useStore} from 'vuex'
import {useRouter, useRoute} from 'vue-router'

import backTop  from '@/components/backTop.vue'
import backHeader  from '@/components/backHeader.vue'
import DFooter from '../index/components/DFooter.vue'
import leftNav  from './leftNav.vue'
import service from '../../service.js';


import {useScrollToParamsId, useGetDevice} from '@/use/diy-use.js'


export default {
  name: 'case',
  components:{backTop,DFooter,backHeader,leftNav},
  setup(){
      const list = ref([]);
      const list2 = ref([]);
      const richText = ref({name:''});

      document.title="应用案例_粤增减材"

      // useScrollToParamsId();

      const targetOffset = useGetDevice()==='pc'?'76':'50';
      const navRef = ref(null);

      const handleClick = (e)=>{
      	console.log(e.target);
      	navRef.value.mobileNavSwitch();
        e.preventDefault();
      }

      let getProductList = async function(){
      	const list1 =(await service({url:"/api/case/category",method: 'get'})).retdata.list;
      	const list2 =(await service({url:"/api/case",method: 'get',params:{action:"list"}})).retdata.list;
      	let idx = 0;
      	list1.forEach((item,index)=>{
      		item.idx = idx;
      		idx++;
      		item.child = list2.filter((subitem,subindex)=>{
      			// if(subitem.category_id === item.id){
	      		// 	subitem.idx=idx;
	      		// 	idx++;
      			// }
      			return subitem.category_id === item.id;
      		})
      	})

      	list.value = list1;
      	console.log(list.value);
      	return list1;
      }
      getProductList()

      const richTextChange = (res)=>{
      	console.log(res);
      	richText.value = res;
      }
      
      return {
      	 targetOffset,
         handleClick,
         list,
         navRef,
         richText,
         richTextChange
      }
  }
}
</script>

<style scoped>
.detail{background: #f1f1f1; min-height: 100vh; overflow: hidden; }

.wrap{width:1100px; margin:74px auto 40px auto; position: relative; overflow: hidden; min-height:calc(100vh - 235px);}

.leftbox{width:290px; position: fixed; }
.leftbox .nav-box{padding:20px 15px; background: #fff;}

.rightbox{margin-left:310px; background: #fff; padding:10px 25px; line-height: 2}
.rightbox h2{font-size:21px; color:#111; font-weight: bold; padding:5px 0; text-align:center; margin:30px 0 10px 0; border-bottom:1px solid #eee;}
.rightbox h3{font-size:18px; color:#333; font-weight: bold; padding:5px 0; margin:15px 0 0px 0; }
.rightbox h4{font-size:16px; color:#333; font-weight: bold; padding:5px 0; margin:15px 0 0px 0; }

@media screen and (max-width:800px) {
.wrap{width:auto; margin:49px auto 10px auto; min-height:calc(100vh - 150px);}
.leftbox .nav-box{padding:0;}
.rightbox{margin-left:0px; padding:10px 10px;}
}
</style>


<style type="text/css">
.product .rich-text img{width: 700px; margin-top:10px;}
.product .rich-text ul{list-style-type: disc; padding-left:20px; font-size:15px;}
.product .rich-text ul li{line-height:1.9; color:#999;}

@media screen and (max-width:800px) {
.product .rich-text img{width: 390px; margin-top:10px;}
}	
</style>
